import { useMutation } from '@apollo/client';
import { useForm } from '@layerise/design-core';
import { hubspotForm } from 'lib/hubspot';
import { metaPages } from 'lib/metapages';
import { useEffect, useState } from 'react';
import { GetStaticProps, NextPage } from 'next';
import { useRouter } from 'next/router';
import {
  SendTwoFactorCodeDocument,
  SigninMutationDocument,
  ValidateTwoFactorCodeByPhoneDocument,
  ValidateTwoFactorCodeMutationDocument,
  ValidateRecoveryCodeDocument,
  InvitedMemeberMutationDocument,
  SignupMutationDocument,
  BillingCurrency,
} from 'types/typed-document-nodes';
import { LoginView } from 'components/auth/LoginView';
import { TwoFactorCodeView } from 'components/auth/TwoFactorCodeView';
import { TwoFactorPhoneView } from 'components/auth/TwoFactorPhoneView';
import { TwoFactorBackupInputView } from 'components/auth/TwoFactorBackupInputView';
import { useCountries } from 'hooks/data/useCountries';
import { useApiErrorHandler } from 'hooks/useApiErrorHandler';
import { InvitationView } from 'components/auth/InvitationView';
import { SignupView } from 'components/auth/SignupView';
import { usePersona } from 'hooks/usePersona';

enum AuthStep {
  Credentials,
  Invitation,
  Signup,
  PhoneInput,
  SmsCode,
  BackupCode,
}

const SigninPage: NextPage = () => {
  const router = useRouter();
  const inviteToken = router.query.inviteToken as string;
  const plan = (router.query.plan || router.query.enterprise) as string;

  const [authStep, setAuthStep] = useState<AuthStep | null>(null);
  const countries = useCountries();
  const personaQuery = usePersona();
  const continent = personaQuery.data?.continent;
  const ipAddress = personaQuery.data?.ip.toString() ?? null;

  useEffect(() => {
    if (router.isReady) {
      if (inviteToken) {
        setAuthStep(AuthStep.Invitation);
      } else if (plan) {
        setAuthStep(AuthStep.Signup);
      } else {
        setAuthStep(AuthStep.Credentials);
      }
    }
  }, [router.isReady]);

  const signInForm = useForm({
    email: `${router.query.email || ''}`,
    password: '',
    remember: true,
  });

  const telForm = useForm({
    telCode: '',
    tel: '',
  });

  const twoFactorForm = useForm({
    code: '',
  });

  const backupCodeForm = useForm({
    code: '',
  });

  const [signup, signupMutationResult] = useMutation(SignupMutationDocument, {
    variables: {
      email: signInForm.inputs.email,
      password: signInForm.inputs.password,
      ipAddress,
      referrer: typeof window === 'undefined' ? '' : window.document.referrer,
      planId: plan,
      currency: continent === 'EU' ? BillingCurrency.Eur : BillingCurrency.Usd,
    },
    onError: useApiErrorHandler(),
    onCompleted: async () => {
      if (process.env.NODE_ENV === 'production') {
        await hubspotForm({
          formId: '69bc4087-9f6b-4c30-8486-bdbf0066f904',
          fields: [
            {
              name: 'email',
              value: signInForm.inputs.email,
            },
          ],
        });
      }
      setAuthStep(AuthStep.PhoneInput);
    },
  });

  const [updateInvitedMember, updateInvitedMemberMutationResult] = useMutation(InvitedMemeberMutationDocument, {
    variables: { password: signInForm.inputs.password, inviteToken },
    onCompleted() {
      setAuthStep(AuthStep.PhoneInput);
    },
    onError: () => {},
  });

  const [signin, signinMutationResult] = useMutation(SigninMutationDocument, {
    variables: signInForm.inputs,
    onCompleted(data) {
      if (data.signin?.twoFactorPhoneLast4) {
        setAuthStep(AuthStep.SmsCode);
        sendCode({
          variables: {
            token: data.signin.twoFactorToken,
          },
          onError: () => {},
        });
      } else {
        setAuthStep(AuthStep.PhoneInput);
      }
    },
    onError: useApiErrorHandler(),
  });

  const [sendCode, sendCodeMutationResult] = useMutation(SendTwoFactorCodeDocument);
  const [validateTwoFactorCodeByPhone, validateTwoFactorCodeByPhoneMutationResult] = useMutation(
    ValidateTwoFactorCodeByPhoneDocument
  );
  const [validateTwoFactorCode, validateTwoFactorCodeMutationResult] = useMutation(
    ValidateTwoFactorCodeMutationDocument
  );
  const [validateRecoveryCode, validateRecoveryCodeMutationResult] = useMutation(ValidateRecoveryCodeDocument);

  const data =
    signupMutationResult.data?.signup ||
    updateInvitedMemberMutationResult.data?.updateInvitedMember ||
    signinMutationResult.data?.signin;
  const twoFactorToken = data?.twoFactorToken;
  const twoFactorPhoneLast4 = data?.twoFactorPhoneLast4;

  const submissionSuccess = () => {
    hubspotForm({
      formId: '9f28f9ff-b27b-4d9b-a484-b4d1f5c392c2',
      fields: [
        {
          name: 'email',
          value: signInForm.inputs.email,
        },
      ],
    }).finally(() => {
      let url = '/';
      const redirect = router.query.redirect;
      const signUpData = signupMutationResult?.data?.signup;
      if (redirect) {
        url = `${redirect}`;
      } else if (signUpData?.lastUsedWorkspaceSlug) {
        url = plan
          ? `/w/${signUpData.lastUsedWorkspaceSlug}/plan?id=${plan}`
          : `/w/${signUpData.lastUsedWorkspaceSlug}/setup`;
      }
      window.location.href = url;
    });
  };

  if (authStep === AuthStep.Credentials) {
    return (
      <LoginView
        form={signInForm}
        loading={signinMutationResult.loading}
        onSubmit={() => {
          signin();
        }}
      />
    );
  }

  if (authStep === AuthStep.Invitation) {
    return (
      <InvitationView
        inviteToken={inviteToken}
        form={signInForm}
        loading={updateInvitedMemberMutationResult.loading}
        onSubmit={() => {
          updateInvitedMember();
        }}
      />
    );
  }

  if (authStep === AuthStep.Signup) {
    return (
      <SignupView
        form={signInForm}
        onLogin={() => {
          setAuthStep(AuthStep.Credentials);
        }}
        onSubmit={() => {
          signup();
        }}
        loading={signupMutationResult.loading}
      />
    );
  }

  if (authStep === AuthStep.PhoneInput) {
    return (
      <TwoFactorPhoneView
        form={telForm}
        loading={sendCodeMutationResult.loading}
        errorMessage={sendCodeMutationResult.error?.message}
        onSubmit={() => {
          sendCode({
            variables: {
              token: twoFactorToken,
              phoneNumber: `${telForm.inputs.tel}`,
              countryCode: `${countries.find(c => c.code === telForm.inputs.telCode)?.diallingCode}`,
            },
            onCompleted() {
              setAuthStep(AuthStep.SmsCode);
            },
            onError: () => {},
          });
        }}
        onBack={() => {
          setAuthStep(plan ? AuthStep.Signup : AuthStep.Credentials);
        }}
      />
    );
  }

  if (authStep === AuthStep.SmsCode) {
    return (
      <TwoFactorCodeView
        form={twoFactorForm}
        phoneLastDigits={twoFactorPhoneLast4 || telForm.inputs.tel.slice(-4)}
        loading={validateTwoFactorCodeMutationResult.loading || validateTwoFactorCodeByPhoneMutationResult.loading}
        errorMessage={
          sendCodeMutationResult.error?.message ||
          validateTwoFactorCodeMutationResult.error?.message ||
          validateTwoFactorCodeByPhoneMutationResult.error?.message
        }
        onBack={() => {
          setAuthStep(twoFactorPhoneLast4 ? AuthStep.Credentials : AuthStep.PhoneInput);
        }}
        onSubmit={code => {
          if (!code || !twoFactorToken) return;
          if (twoFactorPhoneLast4) {
            validateTwoFactorCode({
              variables: {
                code,
                token: twoFactorToken,
                remember: signInForm.inputs.remember,
              },
              onCompleted: submissionSuccess,
              onError: () => {},
            });
          } else {
            validateTwoFactorCodeByPhone({
              variables: {
                activationCode: code,
                phoneNumber: `${telForm.inputs.tel}`,
                countryCode: `${countries.find(c => c.code === telForm.inputs.telCode)?.diallingCode}`,
                startSession: true,
                remember: signInForm.inputs.remember,
                token: twoFactorToken,
              },
              onCompleted: submissionSuccess,
              onError: () => {},
            });
          }
        }}
        onResend={() => {
          validateTwoFactorCodeMutationResult.reset();
          validateTwoFactorCodeByPhoneMutationResult.reset();
          twoFactorForm.setInputs({ code: '' });
          sendCode({
            variables: {
              token: twoFactorToken,
              ...(telForm.inputs.tel && {
                phoneNumber: `${telForm.inputs.tel}`,
                countryCode: `${countries.find(c => c.code === telForm.inputs.telCode)?.diallingCode}`,
              }),
            },
            onError: () => {},
          });
        }}
        onBackupCodesSelected={() => {
          setAuthStep(AuthStep.BackupCode);
        }}
      />
    );
  }

  if (authStep === AuthStep.BackupCode) {
    return (
      <TwoFactorBackupInputView
        form={backupCodeForm}
        onBack={() => {
          setAuthStep(AuthStep.PhoneInput);
        }}
        onSubmit={code => {
          if (!code || !twoFactorToken) return;
          const recoveryCode = (code.match(/.{1,5}/g) || []).map(s => s.toUpperCase()).join(' ');
          validateRecoveryCode({
            variables: {
              token: twoFactorToken,
              recoveryCode,
              remember: signInForm.inputs.remember,
            },
            onCompleted: submissionSuccess,
            onError: () => {},
          });
        }}
        errorMessage={validateRecoveryCodeMutationResult.error?.message}
        loading={validateRecoveryCodeMutationResult.loading}
      />
    );
  }

  return null;
};

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {
      ...metaPages.auth,
    },
  };
};

export default SigninPage;
